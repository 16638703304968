<template>
  <div>
    <div class="max-big-box">
      <!-- <img
        src="~@/assets/login/ackground of the login page@2x.png"
        class="login-img"
      /> -->
    </div>
    <div class="login-layout">
      <h1 style="margin: 0">欢迎登陆</h1>
      <van-form @submit="onSubmit">
        <div v-if="isShowLgin">
          <div class="input-layout">
            <!-- <img
              src="~@/assets/login/mobile phone icon@2x.png"
              class="phone-img"
            /> -->
            <input
              type="text"
              v-model="loginInfo.phone"
              placeholder="请输入账号"
              class="input-user"
            />
          </div>
          <div class="input-layout">
            <!-- <img src="~@/assets/login/password icon@2x.png" class="phone-img" /> -->
            <input
              type="text"
              v-model="loginInfo.password"
              placeholder="请输入密码"
              class="input-user"
            />
          </div>
        </div>
        <!-- 注册页面 -->
        <div v-else>
          <div class="input-layout">
            <!-- <img src="~@/assets/login/password icon@2x.png" class="phone-img" /> -->
            <input
              type="text"
              v-model="phoneNum"
              placeholder="请输入手机号"
              class="input-user"
            />
          </div>
          <div class="input-layout">
            <!-- <img src="~@/assets/login/password icon@2x.png" class="phone-img" /> -->
            <input
              type="text"
              v-model="signInPassword"
              placeholder="设置密码"
              class="input-user"
            />
          </div>
          <div class="input-layout">
            <!-- <img src="~@/assets/login/password icon@2x.png" class="phone-img" /> -->
            <input
              type="text"
              v-model="passwordOk"
              placeholder="确认密码"
              class="input-user"
            />
          </div>
        </div>
        <!-- 提交按钮 -->
        <div class="button-layout">
          <van-button
            mini
            round
            block
            type="info"
            color="linear-gradient(to right, #F9A075, #FF3E46)"
          >
            {{ isShowLgin === true ? "登陆" : "注册" }}
          </van-button>
        </div>
        <div>
          <div v-if="isShowLgin" class="text">
            <div>
              <span>没有账号/去</span>
              <span style="color: #eb4a45" @click="registerBtn">注册</span>
            </div>
          </div>
          <div v-else class="text" @click="registerBtn">返回登陆</div>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { getSignInInfo, login } from "@/api/index";
import { Toast } from "vant";
export default {
  name: "",
  data() {
    return {
      isShowLgin: true, // 判断是否为注册页
      loginInfo: {
        phone: "13500135000", // 登陆用户名
        password: "123456", // 登陆密码
      },
      phoneNum: "", // 注册手机号
      signInPassword: "", // 注册设置密码
      passwordOk: "", // 确认密码
      signInInfo: {},
    };
  },
  mounted() {},
  methods: {
    async onSubmit() {
      if (!this.isShowLgin) {
        this.signInInfo = {
          invite_code: 123456,
          phone: this.phoneNum,
          password: this.signInPassword,
          code: 1234,
        };
        const res = await getSignInInfo(this.signInInfo);
        this.$store.commit("setCookie", res);
        if (this.$store.state.token && this.$store.state.m_id) {
          this.$router.push("/home");
        }
      } else {
        const info = await login(this.loginInfo);
        if (info.code * 1 === 1) {
          this.$store.commit("setCookie", info);
          this.$router.push("/home");
        } else {
          Toast.fail(info.msg);
        }
      }
    },
    registerBtn() {
      this.isShowLgin = !this.isShowLgin;
    },
  },
};
</script>

<style lang="less" scoped>
.max-big-box {
  background-color: #fff9f7;
}
.login-img {
  position: absolute;
  top: 50px;
  left: 45px;
  width: 324px;
  height: 296px;
}
.login-layout {
  position: absolute;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 420px;
  background-color: #fff;
  border-radius: 30px 30px 0 0;
  padding: 26px 32px 48px 32px;
}
.input-layout {
  position: relative;
  padding: 2px 20px;
  width: 350px;
  height: 52px;
  border-radius: 52px;
  box-sizing: border-box;
  border: 1px solid #adadad;
  margin-top: 26px;
  .phone-img {
    position: absolute;
    top: 14px;
    left: 20px;
    width: 20px;
  }
}
.input-user {
  position: absolute;
  top: 2px;
  right: 20px;
  width: 280px;
  height: 44px;
  border: none;
}
.text {
  display: flex;
  justify-content: center;
  color: #6f6f6f;
}
.button-layout {
  display: flex;
  justify-content: center;
  margin: 16px 0;
}
/deep/ .van-button {
  width: 259px;
  height: 52px;
}
/deep/ .van-button__text {
  font-size: 18px;
}
</style>
